import Home from '../views/Home.vue';
import Dashboard from '../views/Dashboard.vue';

export const menus = [
  {
    icon: 'mdi-view-split-vertical',
    text: '商品',
    model: false,
    url: { name: 'product_list', query: { p: 1 } },
    children: [
      { text: '商品列表', url: { name: 'product_list', query: { p: 1 } } },
      { text: '类别管理', url: { name: 'product_category' } },
      { text: '单位管理', url: { name: 'product_unit' } },
      { text: '品牌管理', url: { name: 'product_brand' } },
      { text: '商品草稿', url: { name: 'draft_list', query: { p: 1 } } },
    ],
  },
  {
    icon: 'mdi-account',
    text: '客户',
    model: false,
    url: { name: 'customer_list', query: { p: 1 } },
    children: [
      { text: '客户列表', url: { name: 'customer_list', query: { p: 1 } } },
      {
        text: '客户反馈',
        url: { name: 'customer_feedback_list', query: { p: 1 } },
      },
      {
        text: '客户订阅',
        url: { name: 'customer_news_letter_list', query: { p: 1 } },
      },
    ],
  },
  {
    icon: 'mdi-account-reactivate',
    text: '员工',
    model: false,
    url: { name: 'employee_list', query: { p: 1 } },
    children: [
      { text: '员工设置', url: { name: 'employee_list', query: { p: 1 } } },
      { text: '部门设置', url: { name: 'authority_department' } },
      { text: '角色管理', url: { name: 'authority_role' } },
    ],
  },
  {
    icon: 'mdi-information',
    text: '信息',
    model: false,
    url: { name: 'news_list', query: { p: 1 } },
    children: [
      { text: '新闻列表', url: { name: 'news_list', query: { p: 1 } } },
      { text: '政策列表', url: { name: 'information_list' } },
    ],
  },
  {
    icon: 'mdi-clipboard-multiple',
    text: '询盘',
    model: false,
    url: { name: 'inquiry_list' },
    children: [
      { text: '询盘列表', url: { name: 'inquiry_list', query: { p: 1 } } },
    ],
  },
  {
    icon: 'mdi-chart-box',
    text: '分析',
    model: false,
    url: { name: 'analysis_statistics' },
    children: [
      { text: '数据概览', url: { name: 'analysis_statistics' } },
      { text: '报告', url: { name: 'analysis_report' } },
    ],
  },
  {
    icon: 'mdi-apps',
    text: '应用',
    model: false,
    url: { name: 'app_installed' },
    children: [
      { text: '我的应用', url: { name: 'app_installed' } },
      { text: '应用市场', url: { name: 'app_list' } },
    ],
  },
];

export const setWholesaleMenu = (appIds) => {
  if (appIds.includes('2')) {
    menus[0].children.push(
      { text: '商品评论', url: { name: 'product_comments', query: { p: 1 } } },
    );
  }
  return menus;
};

export const wholesale = [
  {
    path: '/renew-success',
    name: 'renew_success',
    component: () => import(/* webpackChunkName: "group-system" */ '../views/RenewSuccess.vue'),
  },
  {
    path: '/app-renew-success',
    name: 'app_renew_success',
    component: () => import(/* webpackChunkName: "group-system" */ '../views/AppRenewSuccess.vue'),
  },
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: 'home',
        component: Dashboard,
        meta: {
          breadCrumbs: [
            {
              text: '控制台',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/account-renew',
        name: 'system_account_renew',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemAccountRenew.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '账号续费',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/lang-setup',
        name: 'system_lang-setup',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemLangSetup.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '语言',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/delivery',
        name: 'system_delivery',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemDelivery.vue'),
        meta: {
          required: true,
          id: '75',
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '发货与配送',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/online-service',
        name: 'system_online_service',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemOnlineService.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '客服代码设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/mail-service',
        name: 'system_mail_service',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemMailService.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '邮箱服务设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/setup',
        name: 'system_setup',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemSetup.vue'),
        meta: {
          required: true,
          id: '75',
          breadCrumbs: [
            {
              text: '设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/galerry',
        name: 'system_galerry',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemImgGallery.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '图库',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/seo',
        name: 'system_seo',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemSeo.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: 'SEO设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/basic-info',
        name: 'system_basic_info',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemBasicInfo.vue'),
        meta: {
          required: true,
          id: '75',
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '基本信息设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/freeshipping',
        name: 'system_freeshipping',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemFreeshipping.vue'),
        meta: {
          required: true,
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '发货与配送',
              disabled: false,
              to: { name: 'system_delivery' },
              exact: true,
            },
            {
              text: '免邮设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/shippingtemp',
        name: 'system_shippingtemp',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemShippingTemp.vue'),
        meta: {
          required: true,
          // submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '发货与配送',
              disabled: false,
              to: { name: 'system_delivery' },
              exact: true,
            },
            {
              text: '运费模板',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/payment',
        name: 'system_payment',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemPayment.vue'),
        meta: {
          required: true,
          id: '504',
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: 'Paypal支付设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/url',
        name: 'system_url',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemUrl.vue'),
        meta: {
          required: true,
          id: '506',
          // submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '前台域名设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/web-click',
        name: 'system_web_click',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemWebClick.vue'),
        meta: {
          required: true,
          id: '506',
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '防止复制和另存',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/menu',
        name: 'system_menu',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemMenuSetup.vue'),
        meta: {
          required: true,
          id: '505',
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '前台导航设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/exchange_rate',
        name: 'system_exchange_rate',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemExchangeRate.vue'),
        meta: {
          required: true,
          id: '505',
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '汇率设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/apps/friend-link',
        name: 'system_app_friend_link',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemAppFriendLink.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '我的应用',
              to: { name: 'app_installed' },
              disabled: false,
              exact: true,
            },
            {
              text: '友情链接',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/template',
        name: 'system_template',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemTemplateSetup.vue'),
        meta: {
          required: true,
          id: '507',
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '前台模板设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/template/:template',
        name: 'system_template_single',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemTemplateSingle.vue'),
        props: true,
        meta: {
          required: true,
          id: '507',
          submit: true,
          breadCrumbs: [
            {
              text: '设置',
              disabled: false,
              to: { name: 'system_setup' },
              exact: true,
            },
            {
              text: '前台模板设置',
              disabled: false,
              to: { name: 'system_template' },
              exact: true,
            },
            {
              text: '前台模板广告位设置',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/employee-list',
        name: 'employee_list',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemEmployeeList.vue'),
        meta: {
          required: true,
          id: '248',
          breadCrumbs: [
            {
              text: '员工列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/employee-add',
        name: 'employee_add',
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemEmployeeSingle.vue'),
        meta: {
          required: true,
          id: '243',
          submit: true,
          breadCrumbs: [
            {
              text: '员工列表',
              disabled: false,
              to: { name: 'employee_list' },
              exact: true,
            },
            {
              text: '添加员工',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'system/employee-edit/id=:id',
        name: 'employee_edit',
        props: true,
        component: () => import(/* webpackChunkName: "group-system" */ '../views/SystemEmployeeSingle.vue'),
        meta: {
          required: true,
          id: '244',
          submit: true,
          breadCrumbs: [
            {
              text: '员工列表',
              disabled: false,
              to: { name: 'employee_list' },
              exact: true,
            },
            {
              text: '编辑员工',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'draft/list',
        name: 'draft_list',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/DraftList.vue'),
        meta: {
          required: true,
          id: '101',
          breadCrumbs: [
            {
              text: '商品草稿',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/sizechart',
        name: 'product_sizechart',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductSizeChart.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '商品尺码',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/custom-label',
        name: 'product_custom_label',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductCustomLabel.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '自定义商品标签',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/brand',
        name: 'product_brand',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductBrand.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '品牌管理',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/relation/id=:id',
        name: 'product_relation',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductRelation.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '关联商品',
              disabled: true,
              exact: true,
            },
          ],
        },
        props: true,
      },
      {
        path: 'product/comment/goodId=:id',
        name: 'product_comment',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductComment.vue'),
        props: true,
        meta: {
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '评论',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/comments',
        name: 'product_comments',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductComment.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '商品评论',
              disabled: true,
              exact: true,
            },
          ],
          props: true,
        },
      },
      {
        path: 'product/list',
        name: 'product_list',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductList.vue'),
        meta: {
          required: true,
          id: '320',
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/add',
        name: 'product_add',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductAdd.vue'),
        meta: {
          required: true,
          id: '300',
          submit: true,
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '添加商品',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/edit/id=:id',
        name: 'product_edit',
        props: true,
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductEdit.vue'),
        meta: {
          required: true,
          id: '301',
          submit: true,
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '编辑商品',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/copy/id=:id',
        name: 'product_copy',
        props: true,
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductCopy.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '复制商品',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/detail/id=:id&sellerId=:sellerId',
        name: 'product_detail',
        props: true,
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductDetail.vue'),
        meta: {
          required: true,
          id: '321',
          breadCrumbs: [
            {
              text: '商品列表',
              disabled: false,
              to: { name: 'product_list' },
              exact: true,
            },
            {
              text: '商品详情',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/unit',
        name: 'product_unit',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductUnits.vue'),
        meta: {
          required: true,
          id: '68',
          breadCrumbs: [
            {
              text: '单位列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/category',
        name: 'product_category',
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductCategory.vue'),
        meta: {
          required: true,
          id: '27',
          breadCrumbs: [
            {
              text: '类别列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/spec/id=:id&&cate=:cate',
        name: 'product_spec',
        props: true,
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductSpec.vue'),
        meta: {
          required: true,
          id: '53',
          breadCrumbs: [
            {
              text: '类别管理',
              disabled: false,
              to: { name: 'product_category' },
              exact: true,
            },
            {
              text: '规格列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'product/attr/id=:id&&cate=:cate',
        name: 'product_attr',
        props: true,
        component: () => import(/* webpackChunkName: "group-product" */ '../views/ProductAttr.vue'),
        meta: {
          required: true,
          id: '38',
          breadCrumbs: [
            {
              text: '类别管理',
              disabled: false,
              to: { name: 'product_category' },
              exact: true,
            },
            {
              text: '属性列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'customer/list',
        name: 'customer_list',
        component: () => import(/* webpackChunkName: "group-customer" */ '../views/CustomerList.vue'),
        meta: {
          required: true,
          id: '261',
          breadCrumbs: [
            {
              text: '客户列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'customer/feedback',
        name: 'customer_feedback_list',
        component: () => import(/* webpackChunkName: "group-customer" */ '../views/CustomerFeedbackList.vue'),
        meta: {
          required: true,
          id: '380',
          breadCrumbs: [
            {
              text: '客户反馈',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'customer/newsletter',
        name: 'customer_news_letter_list',
        component: () => import(/* webpackChunkName: "group-customer" */ '../views/CustomerNewsLetterList.vue'),
        meta: {
          required: true,
          id: '381',
          breadCrumbs: [
            {
              text: '客户订阅',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'customer/shippig/list/id=:id&&uid=:uid',
        name: 'customer_shippig_list',
        props: true,
        component: () => import(/* webpackChunkName: "group-customer" */ '../views/CustomerShippingList.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '客户列表',
              disabled: false,
              to: { name: 'customer_list', query: { p: 1 } },
              exact: true,
            },
            {
              text: '收货地址列表',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '354',
        },
      },
      // 权限
      {
        path: 'authority/department',
        name: 'authority_department',
        component: () => import(/* webpackChunkName: "group-authority" */ '../views/AuthorityDepartment.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '部门设置',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '6504',
        },
      },
      {
        path: 'authority/role',
        name: 'authority_role',
        component: () => import(/* webpackChunkName: "group-authority" */ '../views/AuthorityRole.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '角色管理',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '6524',
        },
      },
      {
        path: 'authority/role/add',
        name: 'authority_role_add',
        component: () => import(/* webpackChunkName: "group-authority" */ '../views/AuthorityRoleSingle.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '角色管理',
              disabled: false,
              to: { name: 'authority_role' },
              exact: true,
            },
            {
              text: '添加角色',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '6521',
          submit: true,
        },
      },
      {
        path: 'authority/role/edit/id=:id',
        name: 'authority_role_edit',
        props: true,
        component: () => import(/* webpackChunkName: "group-authority" */ '../views/AuthorityRoleSingle.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '角色管理',
              disabled: false,
              to: { name: 'authority_role' },
              exact: true,
            },
            {
              text: '编辑角色',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '6522',
          submit: true,
        },
      },
      {
        path: 'authority/employee/right/id=:id&&name=:name&&mobile=:mobile',
        name: 'authority_employee_right',
        props: true,
        component: () => import(/* webpackChunkName: "group-authority" */ '../views/AuthorityEmployeeRight.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '员工列表',
              disabled: false,
              to: { name: 'employee_list' },
              exact: true,
            },
            {
              text: '员工权限设置',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '6541',
        },
      },
      // 分析
      {
        path: 'analysis/statistics',
        name: 'analysis_statistics',
        component: () => import(/* webpackChunkName: "group-analysis" */ '../views/AnalysisStatistics.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '数据概览',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'analysis/report',
        name: 'analysis_report',
        component: () => import(/* webpackChunkName: "group-analysis" */ '../views/AnalysisReport.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '报告',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'analysis/report/sale',
        name: 'analysis_report_sale',
        component: () => import(/* webpackChunkName: "group-analysis" */ '../views/AnalysisReportSaleAmount.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '销售额报告',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'analysis/report/order_count',
        name: 'analysis_report_order_count',
        component: () => import(/* webpackChunkName: "group-analysis" */ '../views/AnalysisReportOrderCount.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '订单量报告',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'analysis/report/user_views',
        name: 'analysis_report_user_views',
        component: () => import(/* webpackChunkName: "group-analysis" */ '../views/AnalysisReportUserViews.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '访客数报告',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'analysis/report/hot_products',
        name: 'analysis_report_hot_products',
        component: () => import(/* webpackChunkName: "group-analysis" */ '../views/AnalysisReportHotProducts.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '热销商品报告',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'analysis/report/user_price',
        name: 'analysis_report_user_price',
        component: () => import(/* webpackChunkName: "group-analysis" */ '../views/AnalysisReportUserPrice.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '客单价报告',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      // 新闻
      {
        path: 'news/list',
        name: 'news_list',
        component: () => import(/* webpackChunkName: "group-news" */ '../views/NewsList.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '新闻列表',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '7021',
        },
      },
      {
        path: 'news/add',
        name: 'news_add',
        component: () => import(/* webpackChunkName: "group-news" */ '../views/NewsSingle.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '新闻列表',
              to: { name: 'news_list', query: { p: 1 } },
              disabled: false,
              exact: true,
            },
            {
              text: '添加新闻',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '7022',
          submit: true,
        },
      },
      {
        path: 'news/edit/:id',
        name: 'news_edit',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/NewsSingle.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '新闻列表',
              to: { name: 'news_list', query: { p: 1 } },
              disabled: false,
              exact: true,
            },
            {
              text: '编辑新闻',
              disabled: true,
              exact: true,
            },
          ],
          required: true,
          id: '7023',
          submit: true,
        },
      },
      {
        path: 'information/list',
        name: 'information_list',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/InformationList.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '政策列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'information/privacy',
        name: 'information_privacy',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/InformationPrivacy.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '政策列表',
              to: { name: 'information_list' },
              disabled: false,
              exact: true,
            },
            {
              text: '隐私政策',
              disabled: true,
              exact: true,
            },
          ],
          submit: true,
        },
      },
      {
        path: 'information/service',
        name: 'information_service',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/InformationService.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '政策列表',
              to: { name: 'information_list' },
              disabled: false,
              exact: true,
            },
            {
              text: '服务条款',
              disabled: true,
              exact: true,
            },
          ],
          submit: true,
        },
      },
      {
        path: 'information/shipping',
        name: 'information_shipping',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/InformationShipping.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '政策列表',
              to: { name: 'information_list' },
              disabled: false,
              exact: true,
            },
            {
              text: '物流运费政策',
              disabled: true,
              exact: true,
            },
          ],
          submit: true,
        },
      },
      {
        path: 'information/payment',
        name: 'information_payment',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/InformationPayment.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '政策列表',
              to: { name: 'information_list' },
              disabled: false,
              exact: true,
            },
            {
              text: '支付政策',
              disabled: true,
              exact: true,
            },
          ],
          submit: true,
        },
      },
      {
        path: 'information/return',
        name: 'information_return',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/InformationReturn.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '政策列表',
              to: { name: 'information_list' },
              disabled: false,
              exact: true,
            },
            {
              text: '退换货政策',
              disabled: true,
              exact: true,
            },
          ],
          submit: true,
        },
      },
      {
        path: 'information/notification',
        name: 'information_notification',
        props: true,
        component: () => import(/* webpackChunkName: "group-news" */ '../views/InformationNotification.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '公告设置',
              disabled: true,
              exact: true,
            },
          ],
          submit: true,
        },
      },
      // 应用
      {
        path: 'app/installed',
        name: 'app_installed',
        props: true,
        component: () => import(/* webpackChunkName: "group-app" */ '../views/AppInstalled.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '我的应用',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'app/list',
        name: 'app_list',
        props: true,
        component: () => import(/* webpackChunkName: "group-app" */ '../views/AppList.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '应用市场',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'inquiry/list',
        name: 'inquiry_list',
        props: true,
        component: () => import(/* webpackChunkName: "group-inqury" */ '../views/InquiryList.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '询盘列表',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'service/email-send',
        name: 'service_email_send',
        props: true,
        component: () => import(/* webpackChunkName: "group-inqury" */ '../views/MarketingEmailService.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '我的应用',
              to: { name: 'app_installed' },
              disabled: false,
              exact: true,
            },
            {
              text: '邮件促销',
              to: { name: 'marketing_email' },
              disabled: false,
              exact: true,
            },
            {
              text: '发送邮件',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'marketing/email-list',
        name: 'marketing_email',
        props: true,
        component: () => import(/* webpackChunkName: "group-inqury" */ '../views/MarketingEmailList.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '我的应用',
              to: { name: 'app_installed' },
              disabled: false,
              exact: true,
            },
            {
              text: '邮件促销',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'marketing/coupons',
        name: 'marketing_coupon',
        props: true,
        component: () => import(/* webpackChunkName: "group-inqury" */ '../views/MarketingCoupon.vue'),
        meta: {
          breadCrumbs: [
            {
              text: '我的应用',
              to: { name: 'app_installed' },
              disabled: false,
              exact: true,
            },
            {
              text: '优惠券促销',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
      {
        path: 'app/ad-script',
        name: 'app_ad_script',
        props: true,
        component: () => import(/* webpackChunkName: "group-inqury" */ '../views/AppAdScript.vue'),
        meta: {
          submit: true,
          breadCrumbs: [
            {
              text: '我的应用',
              to: { name: 'app_installed' },
              disabled: false,
              exact: true,
            },
            {
              text: '广告代码',
              disabled: true,
              exact: true,
            },
          ],
        },
      },
    ],
  },
];
